class AlphaBeta {
  public board: number[]
  public optimumMove: number
  /*
  0, 1, 2
  3, 4, 5
  6, 7, 8
  */
  private targetArray = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6]
  ]

  constructor () {
    this.board = [0, 0, 0, 0, 0, 0, 0, 0, 0]
    this.optimumMove = -1
  }

  public init (board: number[]) {
    this.board = board
    this.getOptimumMove()
  }

  public numMoves (board: number[]) {
    let num = 0
    for (let i = 0; i < 9; i++) {
      if (board[i] !== 0) {
        num++
      }
    }
    return num
  }

  public evaluateBoard (board: number[]) {
    const dim = this.targetArray.length
    // const runs = [[1, 1, 1], [-1, -1, -1]]
    for (let j = 0; j < dim; j++) {
      const a = this.targetArray[j]
      if (board[a[0]] === 1 && board[a[1]] === 1 && board[a[2]] === 1) {
        return 1
      }
      if (board[a[0]] === -1 && board[a[1]] === -1 && board[a[2]] === -1) {
        return -1 // Spieler
      }
    }
    return 0
  }

  private minimax (board: number[], maximizingPlayer: boolean) {
    const score = this.evaluateBoard(board)
    if (score === 1 || score === -1) {
      return score
    }
    if (this.numMoves(board) === 9) {
      return 0
    }
    if (maximizingPlayer) {
      let value = -2
      for (let j = 0; j < 9; j++) { // Über alle Kindknoten iterieren
        if (board[j] === 0) {
          board[j] = 1
          value = Math.max(value, this.minimax(board, !maximizingPlayer))
          board[j] = 0
        }
      }
      return value
    } else {
      let value = 2
      for (let j = 0; j < 9; j++) { // Über alle Kindknoten iterieren
        if (board[j] === 0) {
          board[j] = -1
          value = Math.min(value, this.minimax(board, !maximizingPlayer))
          board[j] = 0
        }
      }
      return value
    }
  }

  private getRandomMove (ar: number[]) {
    const i = []
    let num = 0
    const dim = ar.length
    for (let j = 0; j < dim; j++) {
      if (ar[j] === 0) {
        i.push(j)
        num++
      }
    }
    const d = Math.floor(Math.random() * num)
    return i[d]
  }

  private getOptimumMove () {
    let optimumVal = -2
    let optimumMove = -1
    for (let j = 0; j < 9; j++) {
      if (this.board[j] === 0) {
        this.board[j] = 1
        const moveVal = this.minimax(this.board, false)
        console.log(j + ' ---- ' + moveVal)
        // Undo the move
        this.board[j] = 0
        if (moveVal > optimumVal) {
          optimumMove = j
          optimumVal = moveVal
        }
      }
    }
    this.optimumMove = optimumMove
    const rand = Math.random()
    if (rand < 0.5) {
      this.optimumMove = this.getRandomMove(this.board)
    }
  }
}

export const alphaBeta = new AlphaBeta()
