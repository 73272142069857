
// https://v3.vuejs.org/guide/transitions-enterleave.html#css-transitions
import { defineComponent } from 'vue'
import { alphaBeta } from '@/data/alpha_beta.ts'

export default defineComponent({
  name: 'TicTacToe',

  data () {
    return {
      board: [0, 0, 0, 0, 0, 0, 0, 0, 0] as number[],
      show_slogan: false as boolean,
      slogans: [
        ['Antibiotisch:', 'akut + chronisch + prophylaktisch'],
        ['Treffsicher:', 'antibakteriell<sup>3,4</sup> + antimykotisch<sup>5</sup>'],
        ['Mikrobiomschonend:', 'intestinal + vaginal']
      ],
      status_blocks: [
        ['<span class="red">Drei gewinnt.</span> Herzlichen Glückwunsch!', 'Sie haben gewonnen!'],
        ['Sie haben leider verloren!', 'Versuchen Sie es einfach noch einmal.'],
        ['Unentschieden!', 'Versuchen Sie es einfach noch einmal.']
      ],
      active_slogan: ['', ''],
      active_status_block: ['Das Spiel beginnt!', 'Tippen Sie dazu mit dem Finger in eins der neun Felder'],
      show_game_status: true as boolean,
      disabled: false as boolean,
      newGame: false as boolean,
      activeSources: false,
      finished: false
    }
  },

  created () {
    this.startExtro()
  },

  methods: {
    move (j: number) {
      this.newGame = false
      this.show_game_status = false
      if (this.disabled === true) {
        return
      }
      this.board[j] = -1
      if (alphaBeta.evaluateBoard(this.board) === -1) {
        this.active_status_block = this.status_blocks[0]
        this.show_game_status = true
        this.disabled = true
        this.finished = true
      }
      if (this.finished === false && alphaBeta.numMoves(this.board) === 9) {
        this.active_status_block = this.status_blocks[2]
        this.show_game_status = true
        this.finished = true
      }
      if (this.finished === true) {
        this.startExtro()
        return
      }
      this.active_slogan = this.getSlogan()
      this.show_slogan = true
      this.disabled = true
      setTimeout(() => {
        this.show_slogan = false
        const k: number = this.getOptimumMove()
        this.board[k] = 1
        if (alphaBeta.evaluateBoard(this.board) === 1) {
          this.active_status_block = this.status_blocks[1]
          this.show_game_status = true
          this.disabled = true
          this.finished = true
          this.startExtro()
        } else {
          this.disabled = false
        }
      }, 3000)
    },

    getOptimumMove () {
      if (alphaBeta.numMoves(this.board) === 0 || alphaBeta.numMoves(this.board) === 1) {
        if (this.board[4] === 0) {
          return 4
        }
        if (this.board[0] === 0) {
          return 0
        }
      }
      alphaBeta.init(this.board)
      return alphaBeta.optimumMove
    },

    getCellClass (value: number) {
      if (value === 1) {
        return 'computer'
      }
      if (value === -1) {
        return 'player'
      }
      return 'default'
    },

    getSlogan () {
      let moves = 2
      for (let j = 0; j < 9; j++) {
        if (this.board[j] === -1) {
          moves++
        }
      }
      const ind = moves % 3
      return this.slogans[ind]
    },

    restart () {
      this.board = [0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.show_slogan = false
      this.active_slogan = ['', '']
      this.active_status_block = ['Das Spiel beginnt!', 'Tippen Sie dazu mit dem Finger in eins der neun Felder']
      this.show_game_status = true
      this.disabled = false
      this.newGame = true
      this.finished = false
    },

    startExtro () {
      setTimeout(() => {
        if (this.newGame === false && this.activeSources === false && this.finished === true) {
          this.$router.push('extro')
        }
      }, 5000)
    },

    getSources () {
      this.activeSources = true
      this.$router.push('sources')
    }
  }
})
